import { EventEmitter } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryTreeDataService, ICategoryTreeFilterClientDto, ICategoryTreeItemClientDto } from '.';

export class CategoryTree {
    public tree: Array<ICategoryTreeItemClientDto> = [];
    private _filter: ICategoryTreeFilterClientDto = { searchValue: '', expanded: true, expandedIds: [], showAll: false };
    private _expandedIds: Array<number> = [];
    private _categoryId: number;
    public expandedIds = new EventEmitter<Array<number>>();
    public selected = new EventEmitter<ICategoryTreeItemClientDto>();

    constructor(private categoryTreeDataService: CategoryTreeDataService) {}

    public filterBy(filter: Partial<ICategoryTreeFilterClientDto>) {
        this._filter = { ...this._filter, ...filter };
        if (filter && filter.expandedIds !== undefined) {
            this._expandedIds = filter.expandedIds;
        } else {
            this._expandedIds = [];
        }
        return this.categoryTreeDataService.categoryTree(this._filter).pipe(
            map((tree) => {
                tree.forEach((item) => {
                    this.fixState(item);
                });
                this.tree = tree;
            })
        );
    }

    public findFirst(comparer: (i: ICategoryTreeItemClientDto) => boolean) {
        return this.treeFindFirst(this.tree, comparer);
    }

    public findAll(comparer: (i: ICategoryTreeItemClientDto) => boolean) {
        return this.treeFindAll(this.tree, comparer);
    }

    public toggleExpand(item: ICategoryTreeItemClientDto) {
        if (!item || !item.hasSubItems) {
            return;
        }
        if (!item.subItems.length) {
            this.expand(item);
        } else {
            this.collapse(item);
        }
        this.expandedIds.emit(this._expandedIds);
    }

    private treeFindFirst(tree: Array<ICategoryTreeItemClientDto>, comparer: (i: ICategoryTreeItemClientDto) => boolean) {
        for (let i = 0; i < tree.length; i++) {
            if (comparer(tree[i])) {
                return tree[i];
            }
            const subItemsMatch = this.treeFindFirst(tree[i].subItems, comparer);
            if (subItemsMatch) {
                return subItemsMatch;
            }
        }
        return null;
    }

    private treeFindAll(tree: Array<ICategoryTreeItemClientDto>, comparer: (i: ICategoryTreeItemClientDto) => boolean) {
        let subItemsMatch: Array<ICategoryTreeItemClientDto> = [];
        for (let i = 0; i < tree.length; i++) {
            if (comparer(tree[i])) {
                subItemsMatch.push(tree[i]);
            }
            subItemsMatch = [...subItemsMatch, ...this.treeFindAll(tree[i].subItems, comparer)];
        }
        return subItemsMatch;
    }

    public select(categoryId: number) {
        this._categoryId = categoryId;
        const previous = this.findFirst((treeItem) => treeItem.isSelected);
        if (previous) {
            previous.isSelected = false;
        }
        if (!categoryId) {
            this.selected.emit(null);
            return of(null);
        }
        let current = this.findFirst((treeItem) => treeItem.id === categoryId);
        if (!current) {
            return this.filterBy({ searchValue: String(categoryId) }).pipe(
                map(() => {
                    current = this.findFirst((treeItem) => treeItem.id === categoryId);
                    if (!current) {
                        this.selected.emit(null);
                        return null;
                    }
                    current.isSelected = true;
                    this.selected.emit(current);
                })
            );
        }
        current.isSelected = true;
        this.selected.emit(current);
        return of(categoryId);
    }

    private fixState(item: ICategoryTreeItemClientDto) {
        if (item.id === this._categoryId) {
            item.isSelected = true;
        } else {
            delete item.isSelected;
        }
        if (item?.subItems?.length) {
            item.isExpanded = true;
            item.subItems.forEach((subitem) => this.fixState(subitem));
        }
        if (!item.isExpanded && this._expandedIds.some((expanded) => expanded === item.id)) {
            this.expand(item);
        }
    }

    private collapse(item: ICategoryTreeItemClientDto) {
        item.isExpanded = !item.isExpanded;
        if (!item.isExpanded) {
            if (this._expandedIds) {
                const at = this._expandedIds.indexOf(item.id);
                if (at >= 0) {
                    this._expandedIds.splice(at);
                }
            }
        }
    }

    private expand(item: ICategoryTreeItemClientDto) {
        if (!item.hasSubItems) {
            return;
        }
        if (this._expandedIds && this._expandedIds.every((exp) => exp !== item.id)) {
            this._expandedIds.push(item.id);
        }
        if (!item.subItems.length) {
            this.categoryTreeDataService.categoryChildren(item.id).subscribe((res) => {
                item.subItems = res;
                this.fixState(item);
            });
        } else {
            item.isExpanded = true;
        }
    }
}
