<div *ngFor="let i of items">
    <div class="treeItem lvl{{ level }}" *ngIf="i[childrenName]">
        <span
            class="expand"
            [attr.data-tid]="'plus_' + i.name"
            *ngIf="(i[childrenName].length > 0 || i.hasSubItems) && (!maxLevel || level < maxLevel)"
            (click)="toggleExpand(i)"
        >
            <i
                class="fas"
                [ngClass]="{
                    'fa-plus-square': !i.isExpanded,
                    'fa-minus-square': i.isExpanded
                }"
            ></i>
        </span>
        <div class="checkbox form-group">
            <span class="nochild" *ngIf="i[childrenName].length < 0 || (maxLevel && level === maxLevel)"></span>
            <input
                id="categorySelectModal_{{ i.id }}"
                [attr.data-tid]="i.name"
                type="checkbox"
                [(ngModel)]="i.isSelected"
                (ngModelChange)="addRemoveSubcategoriesToLevel(i)"
            />
            <label for="categorySelectModal_{{ i.id }}" [ngClass]="{ 'cb-item-selected': i.isSelected, 'cb-item-match': i.isMatch }">{{ i.name }}</label>
        </div>
    </div>
    <div class="subtree" *ngIf="i.isExpanded && (!maxLevel || level < maxLevel)" [ngStyle]="{ 'margin-left.px': 20 }">
        <app-category-select-modal-items
            [childrenName]="childrenName"
            [items]="i[childrenName]"
            [level]="level"
            [maxLevel]="maxLevel"
            [dataType]="dataType"
            [selectedCountryIds]="selectedCountryIds"
            [addRemoveToLevel]="addRemoveToLevel"
            [showAll]="showAll"
        ></app-category-select-modal-items>
    </div>
</div>
