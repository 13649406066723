import { ITreeItemDto } from 'app/models';

export interface ICategorySelect {
    items: Array<ICategoryInfo>;
    maxLevel?: number;
    sectionCategories?: boolean;
    multiple?: boolean;
    dataType: CategorySelectDataType;
    search: boolean;
    countryFilter: boolean;
    main?: number;
    addRemoveToLevel?: number;
    countryId?: number;
    showAll?: boolean;
}

export enum CategorySelectDataType {
    Category = 0,
    ArticleCategory = 1
}

export interface ICategoryInfo extends ITreeItemDto {
    isExpanded?: boolean;
    isSelected?: boolean;
    isFound?: boolean;
    level?: number;
    subItems?: Array<ICategoryInfo>;
    isMain?: boolean;
}
