/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum SignInResult {
    Failed = 0,
    Succeeded = 1,
    SucceededRequireEmailVerification = 2,
    SucceededWeakPassword = 3,
    AccountLocked = 4,
    AccountBlocked = 5,
    SucceededTwoFactorRequired = 6,
    PhoneNumberRequired = 7,
    RequireEmailVerification = 8,
    LoginMethodNotSupported = 9,
    UserNotSyncedWithConsole = 10,
}
