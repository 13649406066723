import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Host, Input, Optional, Output, Provider, SkipSelf, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services/dialogs.service';
import { CategorySelectDataType, ICategoryInfo, ICategorySelect } from './category-info';
import { CategorySelectModalComponent } from './category-select-modal/category-select-modal';

import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { ICategoryBaseDto } from 'app/models/category';

const INPUT_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CategorySelectComponent),
    multi: true
};

@Component({
    selector: 'app-category-select',
    templateUrl: './category-select.html',
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class CategorySelectComponent extends FormInputBase implements ControlValueAccessor {
    @Input()
    dataType: CategorySelectDataType = CategorySelectDataType.Category;
    @Output()
    public mainChange = new EventEmitter<ICategoryInfo>();
    @Input()
    maxLevel?: number;
    @Input()
    sectionCategories?: boolean;
    @Input()
    search = false;
    @Input()
    countryFilter = false;
    @Input()
    useMain?: boolean;
    @Input()
    main?: number;
    @Input()
    disabled: boolean;
    @Input()
    addRemoveToLevel: number;
    @Input()
    countryId: number;
    @Input()
    showAll = false;

    public value: Array<number> = [];
    public items: Array<ICategoryInfo> = [];

    public input = '';
    public get inputIsNumber() {
        return !isNaN(+this.input);
    }
    public mainOutput: ICategoryInfo;
    public n = 0;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        private readonly dialogs: DialogsService,
        private readonly matDialog: MatDialog,
        private readonly http: HttpClient,
        private readonly translate: TranslateService
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'catSel' });
    }

    public openModal() {
        this.matDialog
            .open<CategorySelectModalComponent, ICategorySelect, Array<ICategoryInfo>>(CategorySelectModalComponent, {
                data: {
                    items: this.items,
                    maxLevel: this.maxLevel,
                    sectionCategories: this.sectionCategories,
                    dataType: this.dataType,
                    search: this.search,
                    countryFilter: this.countryFilter,
                    main: this.main,
                    addRemoveToLevel: this.addRemoveToLevel,
                    countryId: this.countryId,
                    showAll: this.showAll
                },
                panelClass: 'dialog-lg'
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.items = res;
                    this.raiseChangeValue();
                }
            });
    }

    private getMain(selectedItems: Array<ICategoryInfo>) {
        if (this.useMain && selectedItems) {
            for (const el of selectedItems) {
                if (el.id === this.main) {
                    el.isMain = true;
                    this.mainOutput = el;
                    this.raiseChangeValue();
                }
            }
        }
    }

    setMain(item: ICategoryInfo) {
        if (this.useMain && !this.disabled) {
            for (const el of this.items) {
                if (el.id !== item.id) {
                    el.isMain = false;
                }
            }
            item.isMain = true;
            this.mainOutput = item;
            this.raiseChangeValue();
        }
    }

    public inputKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            this.plusClicked();
        }
    }

    public plusClicked() {
        if (Number.isFinite(+this.input)) {
            this.addCategory(+this.input, true);
            if (this.addRemoveToLevel) {
                this.addSubcategoriesToLevel(+this.input);
            }
            this.input = '';
        }
    }

    public remove(id: number) {
        const item = this.items.find((x) => x.id === id);

        if (!item) {
            return;
        }

        const index = this.items.indexOf(item);

        if (item.id === this.main) {
            this.mainOutput = null;
            this.main = null;
        }
        this.items.splice(index, 1);

        if (this.addRemoveToLevel) {
            this.removeSubcategoriesToLevel(id);
        } else {
            this.raiseChangeValue();
        }
    }

    public searchCategory(): Array<ICategoryInfo> {
        const foundItems = new Set<ICategoryInfo>();
        if (!+this.input) {
            for (const el of this.items) {
                if (el.name.toLocaleLowerCase().includes(this.input.toLocaleLowerCase())) {
                    foundItems.add(el);
                }
            }
            return Array.from(foundItems);
        }
        return this.items;
    }

    private addCategory(id: number, addedWithPlus = false) {
        this.addCategories(Array.of(id), addedWithPlus);
    }

    private addCategories(ids: Array<number>, addedWithPlus = false) {
        let raiseChange = false;

        const callMainAndChange = () => {
            this.getMain(this.items);
            if (addedWithPlus || raiseChange) {
                this.raiseChangeValue();
            }
        };

        if (this.items.every((x) => !ids.includes(x.id))) {
            const url = this.dataType === CategorySelectDataType.ArticleCategory ? `/api/article-category/name` : `/api/category/base`;
            this.http.post<Array<ICategoryBaseDto>>(url, ids).subscribe((res) => {
                this.items.push(
                    ...ids
                        .map((id) => {
                            const category = res.find((x) => x.id === id);
                            if (!category) {
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                this.dialogs.warningMessage('', this.translate.instant('CategorySelect_NotFound', { 0: id }));
                                raiseChange = true;
                                return null;
                            }
                            return {
                                id: id,
                                name: category?.name || '',
                                level: category?.level,
                                hasSubItems: false,
                                isMatch: false
                            };
                        })
                        .filter((x) => x !== null && !this.items.find((x) => x.id === x.id))
                );
                callMainAndChange();
            });
        }
    }

    updateValue(value: number | Array<number>): void {
        this.items = [];
        if (value) {
            if (Array.isArray(value)) {
                this.addCategories(value);
            } else {
                this.addCategory(value);
            }
        }
    }

    public raiseChangeValue() {
        this.setValue(this.items.map((x) => x.id));
    }

    private setValue(value: Array<number>) {
        this.raiseChange(value);
        this.raiseTouched();
        if (this.useMain) {
            this.mainChange.emit(this.mainOutput);
        }
    }

    private addSubcategoriesToLevel(categoryId: number) {
        this.getSubCategoriesToLevel(categoryId).subscribe((categoryIds) => {
            this.items = [...this.items, ...categoryIds.filter((cat) => this.items.find((x) => x.id !== cat.id))];
            this.raiseChangeValue();
        });
    }

    private removeSubcategoriesToLevel(categoryId: number) {
        this.getSubCategoriesToLevel(categoryId).subscribe((categoryIds) => {
            this.items = this.items.filter((x) => !categoryIds.find((cat) => cat.id === x.id));
            this.raiseChangeValue();
        });
    }

    private getSubCategoriesToLevel(categoryId: number) {
        return this.http.get<Array<ICategoryInfo>>(`api/category/${categoryId}/children-to-level/${this.addRemoveToLevel}/`, {
            params: { countryIds: [this.countryId], tree: false }
        });
    }
}
